import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [0];

export const dictionary = {
		"/(app)/classifieds": [4,[2]],
		"/(app)/explore": [5,[2]],
		"/(app)/explore/search": [6],
		"/(app)/grievance": [~7,[2]],
		"/(app)/langChangeModal": [8,[2]],
		"/(app)/profile": [9,[2]],
		"/(app)/recharge": [10,[2]],
		"/signin": [~16],
		"/(app)/tag": [~11,[2]],
		"/(app)/tag/[tagHash]/[[feedType]]": [~12,[2]],
		"/(app)/topic/[topicId]": [~13,[2]],
		"/(app)/trends": [14,[2]],
		"/(app)/video/feed": [15,[2]],
		"/(app)/[[home=trending]]/[[lang=language]]": [~3,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';